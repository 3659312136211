import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { Link } from 'react-scroll';
import { ChevronDown } from 'react-bootstrap-icons';
import PortfolioContext from '../../context/context';
import BackgroundImage from '../Image/BackgroundImage'

const StyledScrollCta = styled.span`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 40px;
  text-align: center;
`

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <BackgroundImage />


      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{name || 'Your Name'}</span>
            <br />
            {subtitle}
          </h1>
        </Fade>
        <StyledScrollCta className="scroll-cta">
          <Link to="about" smooth duration={1000}>
            <div className="mb-4">Know more about what I do</div>

            <Fade top bottom duration={1500} delay={1000} forever>
                <ChevronDown size={24}/>
            </Fade>
          </Link>
        </StyledScrollCta>
      </Container>
    </section>
  );
};

export default Header;
